$(function () {
    if($('.homepage').length) {
        $('.video-holder .arrow').click(function () {
            $('html, body').animate({
                scrollTop: $("header").offset().top
            }, 1000);
        });
    }




    $('.partner-slider').slick({
        autoplay: true,
        draggable: true,
        fade: false,
        speed: 1000,
        arrows: true,
        dots: false,
        slidesToShow: 6,
        slidesToScroll: 6,
        slide: '.slide',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
});