$(function () {
   if($('.project-detail-page').length) {
       $('.detail-slider').slick({
           autoplay: true,
           draggable: true,
           fade: false,
           speed: 1000,
           arrows: true,
           dots: false,
           slidesToShow: 1,
           slidesToScroll: 1,
           slide: '.slide'
       });
   }
});